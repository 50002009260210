
import {Options, Vue} from 'vue-class-component';
import {colorLog} from "@/utils";
import{ h } from 'vue';
import MetaSwitch from "@/components/MetaSwitch.vue";
import SvgIcon from '@/components/SvgIcon.vue';
import anime from "animejs";
@Options<Subscribe>({
  components: { MetaSwitch,
    SuperInfo:{
      props:['info'],
      render(){
        return h('span',{class:'pi-item-sup'},[h(SvgIcon,{class:"pi-is-icon",name:"info"}),h('p',{class:"pi-is-popper"},this.info)])
      },
    }
  },
  props: {}
})
export default class Subscribe extends Vue {
  isAnnually = true;
  currentUserCreditsLeft = 0;
  plan: 'standard' | 'recharge' = 'standard';
  purchaseCreditCount = 0;

  handleBeforePlanCardEnter(el:HTMLElement){
    // const isLeft = el.hasAttribute('left');
    anime.set(el,{
      opacity:0
    });
  }
  handlePlanCardEnter(el:HTMLElement,done:() => void){
    anime({
      targets:el,
      opacity:1,
      complete:() => done(),
      duration:300,
      easing:'easeInCubic'
    });
  }
  handleBeforePlanCardLeave(el:HTMLElement){
    // anime.set(el,{
    //   position:'absolute',
    //   left:0,
    //   right:0,
    // });
    // colorLog('handleBeforePlanCardLeave');
  }
  handlePlanCardLeave(el:HTMLElement,done:() => void){
    const isLeft = el.hasAttribute('left');
    anime({
      targets:el,
      opacity:[1,0],
      complete:() => done(),
      duration:300,
      easing:'easeOutCubic'
    });
  }
}
