
import {Options, Vue} from 'vue-class-component';

@Options<MetaSwitch>({
  components: {},
  props: {
     value:{
       required:false,
       default:false
     }
  },
  watch:{
    value:{
      handler(newVal:boolean){
        this.interValue = newVal;
      },
      immediate:true
    },
    interValue(newVal:boolean){
      this.$emit('update:value',newVal);
    }
  }
})
export default class MetaSwitch extends Vue {
  value!:boolean;
  interValue = true;

  toLeft(){
    this.interValue = true;
    this.$emit('change',true);

  }
  toRight(){
    this.interValue = false;
    this.$emit('change',false);

  }
  toggleSwitchValue(){
    if(this.interValue){
      this.toRight();
    }else{
      this.toLeft();
    }
  }
}
